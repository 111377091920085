.App {
  text-align: center;
  padding:0 200px;
}

.faq-row-wrapper {
  padding-bottom: 20px;
  text-align: left;
}

.faq-row-wrapper .faq-title h2 {
  font-size: 24px;
}

.separator {
  width: 100px;
  margin: 20px auto;
}

.dream {
  max-width: 500px;
    margin: 10px auto;
    padding-bottom: 25px;
}

.dream .date {
  font-style: italic;
}

nav ul li {
    list-style-type: none;
    display: inline;
    margin-right: 15px;
}